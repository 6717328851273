<template>
	<main id="story" class="story-container stack-vertical ge-page">
 		<section class="story-header dgrid-body dgrid-container page-header header-reverse" style="grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);">
			<div class="story-header-text stack-vertical page-title">
				<h1 v-if="storyData.title ? storyData.title.length : false">{{storyData.title}}</h1>
				<h3 v-if="storyData.subtitle ? storyData.subtitle.length : false">{{storyData.subtitle}}</h3>
				<div class="story-metadata stack-vertical" data-align="start"> 
					<p v-if="storyData.author ? storyData.author.length : false">{{storyData.author}}</p>
					<p>{{new Date(storyData.date) | moment('DD MMMM YYYY')}}</p>
				<!-- 	<interface-user-and-tags
						ref="user-and-tags"
						:user-actions="userActionsFiltered"
						@click-user-action="userAction"
					/> -->
					<div class="page-buttons-cont stack-horizontal">
						<base-button v-for="(btn, i) in userActions" :key="i" :text="btn.text" type="tertiary" @clicked="userAction(btn.icon)" />
					</div>
				</div>
			</div>
			<div v-show="animatedCoverLoadCounter === numAnimatedCoverImages" class="page-header-carousel-container relative story">
				<img @load="onAnimatedCoverImageLoaded" v-if="storyData.cover_graphics_landscape" :src="parseMedia(storyData.cover_graphics_landscape)" class="animated-cover-image landscape">
				<img @load="onAnimatedCoverImageLoaded" v-if="storyData.cover_graphics_portrait" :src="parseMedia(storyData.cover_graphics_portrait)" class="animated-cover-image portrait">
				<img @load="onAnimatedCoverImageLoaded" v-if="storyData.cover_graphics_square" :src="parseMedia(storyData.cover_graphics_square)" class="animated-cover-image square">
			</div>
		</section>
		<section class="bordered-top"></section>
		<div v-if="storyData.introduction" class="dgrid-container dgrid-body story-introduction text--subtitle">{{storyData.introduction}}</div>
		<section class="story-body stack-vertical">
			<div v-for="item in storyDataContents" class="full-width" :data-type="item.type">
				<p v-if="item.type == 'subtitle'" class="dgrid-body text--subtitle story-body-text">{{item.content}}</p>
				<div v-if="item.type == 'Attachment'" class="dgrid-body story-body-text flow" style="--flow-space: var(--s1)">
					<h4>{{item.text}}</h4>
					<div class="page-buttons-cont">
						<a :href="parseMedia(item.media)" download target="_blank"><base-button :text="$t('download_asset')" type="tertiary" /></a>
					</div>
				</div>
									
				<div v-if="item.type == 'Multimediale'" :class="(item.full_width ? 'media-img full-width' : 'dgrid-body dgrid-container')">
					<div class="stack-vertical full-width story-content-item">
						<img v-if="item.media_type == 'image'" 
							:src="parseMedia(item.media)"
							@load="limitVerticalImageSize"
						/>
						<p v-if="item.author" :class="item.full_width ? 'dgrid-container dgrid-body' : '' + ' story-author'">{{item.author}}</p>
					</div>
					<base-video v-if="item.media_type == 'video'" :class="item.full_width ? '' : 'dgrid-body dgrid-container'" :source="parseMedia(item.media)" :title="item.author ? (item.author.length ? item.author : null) : null"></base-video>
				</div>
				<p v-if="item.text && item.type == 'Testo' && item.text.length" class="story-body-text dgrid-body dgrid-container marked" v-html="parsedText(item.text)"></p>
				<div v-if="item.type == 'Citazione'" class="story-quote dgrid-container dgrid-body">
					<div v-if="!tablet" class="story-quote-space bordered-right"></div>
					<div class="story-quote-text-cont stack-vertical">
						<h2 v-if="item.text" v-html="parsedText(item.text)" class="marked"></h2>
						<!-- <h2>Pellegrino Artusi, con La Scienza in cucina e l’Arte di mangiar bene, diventa il punto di raccolta dove convogliano storie, aneddoti e testimonianze di un’Italia appena unita.</h2> -->
						<div v-if="item.quote" class="stack-vertical story-quote-names">
							<p class="text--body2">{{item.quote}}</p>
							<!-- <p class="text--body2">Gastronomo</p> -->
						</div>
					</div>
				</div>
				<div v-if="item.type == 'images-carousel'" class="relative stack-horizontal images-carousel">
					<div v-for="image in imagesCarousel" class="stack-vertical images-carousel-item">
						<div class="stack-vertical">
							<img class="media-item" :src="image.src"/>
							<div class="stack-horizontal images-carousel-texts">
								<!-- <p>uno</p> cuoricino quando ci sarà il profilo -->
								<p>{{image.name}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
<!-- 			<div v-if="computedTags.length" class="dgrid-body dgrid-container">
				<div class="stack-horizontal story-tags">
					<base-tag v-for="t in computedTags" :text="t" :key="t"/>
				</div>
			</div> -->
		</section>
		<section class="bordered-top" v-if="!relatedSheetsLoaded || relatedSheets.length"></section>

		<section class="" v-if="!relatedSheetsLoaded || relatedSheets.length">
			<interface-cards-carousel 
					:title="$t('storia_titolo_sez_schede_correlate')"
					:scrollable="true" 
					:filters="relatedSheetsFiltersAvailable"
					:columns="4"
					@filter-clicked="filterClicked"
				>					
				<template v-if="!relatedSheetsLoaded">
					<base-cs-sheet
						v-for="i in 4"
						:key="i"
						skeleton
					/>
				</template>

				<template v-else-if="relatedSheetsLoaded && relatedSheets.length">
					<a v-for="item in relatedSheets" :href="`sheet/${item.slug}`" :key="item.id">
						<base-cs-sheet
							:item="item"
							ignore-max-width
							@clicked="goToSheet(item.slug)"
						/>						
					</a>
				</template>

				<p v-else> {{ $t('no_related_sheets') }} </p>

			</interface-cards-carousel>
		</section>
		<section class="bordered-top" v-if="!relatedStoriesLoaded || relatedStories.length"></section>
		<section id="related-stories" class="dgrid-body flow" v-if="!relatedStoriesLoaded || relatedStories.length">
			<h3>{{$t('storia_titolo_sez_storie_correlate')}}</h3>
			<div v-if="relatedStoriesLoaded && relatedStories.length" class="view-body dgrid-container dgrid-minw-wrap">

				<template v-if="!relatedStoriesLoaded">
					<base-cs-story
						v-for="i in 4"
						:key="i"
						skeleton
					/>					
				</template>

				<template v-else-if="relatedStoriesLoaded && relatedStories.length">
					<a v-for="story in relatedStories" :href="`stories/${story.slug}`" :key="story.id">
						<base-cs-story
							:item="story"
							@clicked="$router.push({name: 'story', params: { slug: story.slug }})"
						/>						
					</a>	
				</template>

				<p v-else> {{ $t('no_related_stories') }} </p>
			</div>
		</section>
		<InterfaceShareDesktop 
			:visible="shareDesktopVisible" 
			@exit-modal="() => shareDesktopVisible = false"
			@social-share="socialShare"
		/>
	</main>
</template>

<script>
import cmsItem from '@mixins/cmsItem.js';
import InterfaceUserAndTags from '@components/InterfaceUserAndTags.vue';
import InterfaceCardsCarousel from '@components/InterfaceCardsCarousel.vue';
import InterfaceShareDesktop from '@components/InterfaceShareDesktop.vue';
import mockup from '@mockup/mockup_data.js';
import utils from '@js/utils.js';
import _ from 'lodash';
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import * as marked from 'marked';
export default {
	name: 'Story',
	mixins: [ cmsItem ],
	components: {
		InterfaceUserAndTags,
		InterfaceCardsCarousel,
		InterfaceShareDesktop
	},
	props: {
		isMockup: Boolean, // TODO TEMPORARY ROUTER PROP,
		id: { // ROUTE PROP
			type: String,
			required: false
		},
		slug: { // ROUTE PROP
			type: String,
			required: true
		}
	},
	data() {
		return {
			itemType: "stories",
			imagesCarousel: [
				{
					name: 'immagine uno',
					src: require('@assets/images/mockup_image_1.png')
				},
				{
					name: 'immagine due',
					src: require('@assets/images/mockup_image_2.png')
				},
				{
					name: 'immagine tre',
					src: require('@assets/images/mockup_image_3.png')
				}
			],
			// userActions: [
			// 	// {icon: 'favorite'},
			// 	// {icon: 'share'},
			// 	// {icon: 'hyperlink'},
			// 	{icon: 'share', text: this.$t('share'), tooltipHover: this.$t('share'), tooltipClicked: this.$t('shared'), clicked: false},
			// 	{icon: 'hyperlink', text: this.$t('copy_link'), tooltipHover: this.$t('copy_link'), tooltipClicked: this.$t('copied_link'), clicked: false}
			// ],
			userActions: [
				// {icon: 'favorite'},
				// {icon: 'download', text: this.$t('download_asset'), tooltipHover: this.$t('download_asset'), tooltipClicked: this.$t('downloaded_asset'), clicked: false},
				{icon: 'share', text: this.$t('share'), tooltipHover: this.$t('share'), tooltipClicked: this.$t('shared'), clicked: false},
				// {icon: 'hyperlink', text: this.$t('sheet_source'), tooltipHover: this.$t('copy_link'), tooltipClicked: this.$t('copied_link'), clicked: false},
			],
			mockup: mockup,
			utils: utils,
			stylesStoryCollage: [
				'left: 0; height: 100%: width: auto;',
				'left: 0; top: 0; bottom: 0; margin: auto;',
				'right: 0; top: 0;',
			],
			relatedType: 'topic',
			relatedSheetsFilters: [
				{
					type: 'topic',
					label: this.$t('global_filtro_correlati_categoria')
				},
				{
					type: 'database',
					label: this.$t('global_filtro_correlati_database')
				},
				{
					type: 'institution',
					label: this.$t('global_filtro_correlati_ente')
				}
			],
			relatedSheets: [],
			relatedStories: [],
			relatedSheetsLoaded: false,
			relatedStoriesLoaded: false,
			shareDesktopVisible: false,
			animatedCoverLoadCounter: 0
		}
	},
	watch: {
		item(n, o) {
			if(n.slug) {
				this.loadAllData();
			}
		}
	},
	computed: {
		...mapState({
			mobile: state => state.interface.mobile,
			tablet: state => state.interface.tablet
		}),
		numAnimatedCoverImages() {
			return [this.storyData.cover_graphics_landscape, this.storyData.cover_graphics_portrait, this.storyData.cover_graphics_square].filter(el => el).length;
		},
		storyData() {
			return this.isMockup ? mockup.storyData : this.item;
		},
		storyImage() {
			return utils.parseMediaUrl(this.item.media_file);
		},
		storyDataContents() {
			let temp =  this.storyData ? (this.storyData.story_contents ? this.storyData.story_contents.sort((a, b) => a.order > b.order ? 1 : -1) : []) : []
			return temp.filter(el => {
				if(el.type == 'Testo' && el.text?.length) {
					return el
				}
				if(el.type !== 'Testo'){
					return el
				}
			})
		},
		computedTags() {
			return this.storyData ? (this.storyData.tags?.length ? this.storyData.tags.split(',') : []) : [] 
		},
		relatedSheetsFiltersAvailable() {
			return this.relatedSheetsFilters.filter(el => this[el.type]).map(el => {
				return {...el, selected: el.type == this.relatedType}
			});
		},
		userActionsFiltered() {
			let userActions = _.cloneDeep(this.userActions)
			if(this.storyData) {
				if(this.mobile) {
					userActions.splice(userActions.findIndex(el => el.icon == 'hyperlink'), 1);	
				}else {
					userActions.splice(userActions.findIndex(el => el.icon == 'share'), 1);
				}
			}
			// console.log(_.cloneDeep(userActions))
			return userActions
		}
	},
	methods: {
		...mapActions("cms", ["getListBasic"]),
		...mapActions([
			'getSearchStories',
			'sheets/getSheetById',
			'sheets/getSheets',
		]),
		onAnimatedCoverImageLoaded() {
			this.animatedCoverLoadCounter++;
			console.log("img loaded this.animatedCoverLoadCounter, this.numAnimatedCoverImages", this.animatedCoverLoadCounter, this.numAnimatedCoverImages)
		},
		parseMedia(media) {
			return utils.parseMediaUrl(media);
		},
		changeMouseImagesStatus($event, status) {
			this.mouseInsideImages = status
		},
		parsedText(text) {
			return marked.parse(text);
		},
		async getRelatedSheets() {
			if (!this.relatedSheetsLoaded) {
				// this.$set(this, 'relatedSheets', [{}, {}, {}, {}]);
				this.relatedSheetsLoaded = true;
				let args = { page_size: 5 }
				//topic, database, institution (are computed properties)
				if(this.storyData.id) {
					let urls = this.storyData.story_contents.map(el => el.url).filter(el => el)
					if(urls.length){
						urls = urls.join(',')
						let sheetsIds = urls.split(',')
						// let sheetsIds = this.storyData.url.split(',')
						let sheets = []
						for(let x = 0; x < sheetsIds.length; x++) {
							let id = sheetsIds[x];
							let temp = await this['sheets/getSheetById'](id.replace(' ', ''))
							sheets.push(temp);
							this.relatedSheets = sheets.filter(el => el.slug != this.slug).slice(0,4)
						}
					}
				}
				// this.relatedSheets = res.json.filter(el => el.id != this.id).slice(0,4);				
			}

		},
		async getRelatedStories() {
			if (!this.relatedStoriesLoaded) {
				if (!this.item.tags.length) {
					this.relatedStoriesLoaded = true;
					return;
				}
				let tags = this.item.tags.split(";");
				let args = { page_size: 5, tags: tags }
				let res = await this.getListBasic({listName: "stories", args});
				let relatedStories = res.filter(el => el.slug != this.slug).slice(0,4);
				this.$set(this, 'relatedStories', relatedStories);
				this.relatedStoriesLoaded = true;
			}
		},
		computeAvailableFilters() {
			for (let i = 0; i < this.relatedSheetsFilters.length; i++) {
				if (this[this.relatedSheetsFilters[i].type]) {
					this.relatedType = this.relatedSheetsFilters[i].type;
					break;
				}
			}
		},
		filterClicked(type) {
			// console.log('filterClicked', type);
			this.relatedType = type;
			this.getRelatedSheets();
		},
		loadAllData() {
			(async () => {
				// console.log("sheetData", _.cloneDeep(this.sheetData))
				this.computeAvailableFilters();
				this.getRelatedSheets();
				this.getRelatedStories();
			})()
		},
		goToSheet(slug) {
			this.$router.push({name: 'sheet', params: {slug}})
		},
		userActionOld(type) {
			console.log('userAction', type);
			switch (type) {
				case 'download':
					this.downloadImage();
					break;
				case 'share':
					utils.copyLink(null, this.mobile, this.storyData.title, '');
					this.userActions.find(el => el.icon === type).clicked = true
					setTimeout(() => {
						this.userActions.find(el => el.icon === type).clicked = false;
						this.$refs['user-and-tags'].hovered = []
					}, 1000)
					break;
				case 'hyperlink':
					utils.copyClipboard(location.href, null, 'GeCA | Geoportale della Cultura Alimentare: '+this.storyData.title)
					this.userActions.find(el => el.icon === type).clicked = true
					setTimeout(() => {
						this.userActions.find(el => el.icon === type).clicked = false;
						this.$refs['user-and-tags'].hovered = []
					}, 1000)
					break;
			}
		},
		userAction(type) {
			console.log('userAction', type);
			switch (type) {
				case 'download':
					this.loadActionUser = 'download'
					this.downloadImage()
						.then(res => {
							this.userActions.find(el => el.icon === type).clicked = true
							setTimeout(() => {
								this.userActions.find(el => el.icon === type).clicked = false;
								this.$refs['user-and-tags'].hovered = []
								this.loadActionUser = ''
							}, 1000)
						})
					break;
				case 'share':
					if(!this.mobile) {
						this.shareDesktopVisible = true;
					}else {
						utils.copyLink(null, this.mobile, this.sheetData.title, '');
						this.userActions.find(el => el.icon === type).clicked = true
						setTimeout(() => {
							this.userActions.find(el => el.icon === type).clicked = false;
							this.$refs['user-and-tags'].hovered = []
						}, 1000)
					}
					break;
				case 'hyperlink':
					utils.copyClipboard(location.href, null, 'GeCA | Geoportale della Cultura Alimentare: '+this.sheetData.title)
					this.userActions.find(el => el.icon === type).clicked = true
					setTimeout(() => {
						this.userActions.find(el => el.icon === type).clicked = false;
						this.$refs['user-and-tags'].hovered = []
					}, 1000)
					break;
				case 'open_url':
					let a = document.createElement('a')
					a.target = '_blank';
					a.style.display = 'hidden'
					a.href = this.sheetData.url
					document.body.append(a)
					a.click()
					a.remove()
					break;
			}
		},
		limitVerticalImageSize(ev) {
			let target = ev.target;
			if (target.height >= target.width) {
				target.classList.add("limited-size");
			}
		},
		socialShare(social) {
			let mainText = {it: `Ecco per te una scheda del Geoportale\n\n`, en: `Here is for you a Geoportal sheet\n\n`}

			switch (social) {
				case 'whatsapp':
					// document.querySelector('#test_whatsapp').click()
					window.open('https://wa.me/?text='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href, '_blank');
					break;
				case 'facebook':
					window.open('https://www.facebook.com/sharer.php?u='+location.href, '_blank');
					break;
				case 'x':
					window.open('https://twitter.com/intent/tweet?text='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href, '_blank');
					break;
				case 'email':
					window.open('mailto:test@gmail.com?subject=&body='+encodeURIComponent(mainText[this.$i18n.locale]) + location.href);
					break;
			}
		}
	},
	mounted() {
		if(this.storyData) {
			if(this.storyData.story_contents){
				this.loadAllData();
			}
		}
		// console.log('mounted, isMockup:', this.isMockup)
		// this.$moment.updateLocale('it')
		// console.log(this.mouseInsideImages)
		// console.log('video from mockup', this.mockup.storyData.body.find(el => el.type == 'video'))
	},
	beforeDestroy() {
		this.relatedSheetsLoaded = false;
		this.relatedStoriesLoaded = false;
	}
}
</script>
<style lang="scss">
	@import '@css/variables.scss';
	@import '@css/commons/pages.scss';

	#story {
		padding-bottom: var(--s5);
		// --flow-space-horizontal: var(--s4);
		gap: var(--s5);
	}

	#story .bordered-top {
		// margin-top: var(--s5);
	}
	
	.story-header.page-header .home-page.page-title {
		margin: 0; 
	}

	.page-header.story-header {
		// gap: var(--s5);
		width: unset;
		padding-top: var(--s5);
	}

	.story-header .page-header-carousel-container {
		top: 0;
	}

	.story-header .animated-cover-image {
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		mix-blend-mode: multiply;
		transition: transform 0.5s ease-in-out;
	}
	.story-header .page-header-carousel-container:hover .animated-cover-image.landscape {
		transform: translateY(1rem);
	}
	.story-header .page-header-carousel-container:hover .animated-cover-image.portrait {
		transform: translateX(-1rem);
	}
	.story-header .page-header-carousel-container:hover .animated-cover-image.square {
		transform: translateY(-1rem);
	}

	.story-header > * {
		min-width: 0px;
		max-width: 100%;
	}

	.story-header-text {
		--flow-space-horizontal: var(--s4);
	}

	.story-metadata {
		--flow-space-horizontal: var(--s0);
	}

	.story-metadata .user-and-tags {
		margin-top: var(--s1);
	}

	.story-body {
		--flow-space-horizontal: var(--s5);
		// padding: var(--s5) 0;
	}

	.story-body .story-body-text, 
	.story-introduction {
		// width: 70%;
		--dgrid-col: 2;
		width: calc((100% - (var(--dgrid-col) - 1)*var(--dgrid-gap))/var(--dgrid-col));
	}
	
	.story-body .media-img {
		width: 100%;
	}

	.story-quote-text-cont {
		padding: var(--s2) 0;
		--flow-space-horizontal: var(--s4);
	}

	#related-stories {
		--flow-space: var(--s4);
	}

	.story-content-item {
		--flow-space-horizontal: var(--s2);
	}

	.story-content-item .story-author {
		color: var(--color-primary-50);
	}

	@media (min-width: $breakpoint-m) {
		.story-body .story-body-text,
		.story-introduction {
			margin: auto;
		}
	}

	@media (max-width: $breakpoint-m) {
		#story {
			gap: var(--s3);
		}
		.story-body {
			gap: var(--s3);
		}
		#story .bordered-top {
			// margin-top: var(--s3);
		}
		#story .story-body {
			// padding-top: var(--s3);
		}
		.story-body .story-body-text {
			width: 100%;
		}
		.page-header.story-header {
			padding: var(--s3) 0;
		}
		.story-header, .story-quote {
			grid-template-columns: repeat(auto-fit, 100%) !important;
		}
		.story-body .story-body-text,
		.story-introduction {
			width: unset;
		}

		#related-stories {
			--flow-space: var(--s3);
		}
	}

	.story-other-stories {
		--dgrid-fix-col: 2;
		--dgrid-min-width: 40%;
	}

	.story-video-cont {
		position: relative;
	}

	.story-video {
		width: 100%;
		height: auto;
	}

	.video-icon {
		position: absolute; 
		left: 50%; 
		top: 50%; 
		width: var(--s4);
		height: var(--s4);
		transform: translate(-50%, -50%);
		z-index: 1;
		cursor: pointer;
	}

	.images-carousel {
		overflow: auto;
		align-items: center;
		padding: 0 var(--dgrid-body-margin);
	}
	.images-carousel .media-item {
		max-height: 90vh;
		max-width: 30vw;
		height: 100%;
		width: auto;
	}

	.images-carousel-item {
		--flow-space-horizontal: var(--s2);
	}

	.story-quote {
		grid-template-columns: 1fr 10fr;
	}

	.story-quote-names {
		--flow-space-horizontal: 0;
	}

	.story-quote-space {
		// width: 20rem;
	}

	.story-quote-text {
		// --flow-space-horizontal: var(--s4);
	}

	.images-carousel-texts {
		--flow-space-horizontal: var(--s1);
		color: var(--color-primary-50);
	}

	.story-tags {
		--flow-space-horizontal: var(--s0);
	}

	img.limited-size {
		height: 80vh;
		object-fit: contain;
	}

	@import '@css/components/someButtons.scss';

</style>
