<template>
	<div :class="`geca-share-desktop full-width full-height ${visible ? 'visible' : ''}`" @click="$emit('exit-modal')">
		<div class="geca-share-desktop-box relative">
			<base-icon name="delete" class="geca-share-desktop-exit" @icon-clicked="$emit('exit-modal')" /> 
			<p class="geca-share-desktop-title capitalize-first">{{$t('share')}}</p>
			<div class="share-buttons">
				<div v-for="social in socials" class="share-button" @click="$emit('social-share', social.name)">
					<img class="share-button-icon" :src="social.icon" >
					<p class="capitalize-first">{{social.name}}</p>
				</div>
			</div>
			<div class="copy-link-cont full-width">
				<p class="cut-text">{{linkToCopy}}</p>
				<button class="copy-link-btn capitalize-first" @click="copyLink">{{linkCopied ? $t('copied_link') : $t('copy')}}</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		name: 'InterfaceShareDesktop',
		data() {
			return {
				socials: [
					{name: "whatsapp", icon: require("@assets/images/socials/whatsapp.png")},
					{name: "facebook", icon: require("@assets/images/socials/facebook.png")},
					{name: "email", icon: require("@assets/images/socials/email.png")},
					// {name: "messenger", icon: require("@assets/images/socials/messenger.png")},
					{name: "x", icon: require("@assets/images/socials/twitter.png")},
				],
				linkToCopy: '',
				linkCopied: false
			}
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
		},
		methods: {
			stopPropagationModal() {
				document.querySelector('.geca-share-desktop-box').onclick = e => e.stopPropagation();
			},
			assignLinkToCopy() {
				this.linkToCopy = location.href
			},
			copyLink() {
				navigator.clipboard.writeText(this.linkToCopy).then(
				() => {
					/* clipboard successfully set */
					this.linkCopied = true;
					setTimeout(() => {
						this.linkCopied = false;
					}, 1500);

				}, () => {
					/* clipboard write failed */
				});
			}
		},
		mounted() {
			this.stopPropagationModal();
			this.assignLinkToCopy();
		}
	}
</script>

<style type="text/css" lang="scss">
	
	.geca-share-desktop {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.4);
		display: flex;
		justify-content: center;
		align-items: center;
		transition-property: opacity;
		transition-duration: .2s;
		transition-timing-function: ease-in-out;
		opacity: 0;
		z-index: 999;
		pointer-events: none;
	}

	.geca-share-desktop-title {
		font-size: var(--s1);
	}

	.geca-share-desktop.visible {
		opacity: 1;
		pointer-events: all;
	}

	.geca-share-desktop-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--s2);
		padding: var(--s1);
		background-color: var(--color-white);
		border-radius: var(--radius);
	}

	.geca-share-desktop-exit {
		position: absolute;
		right: var(--s1);
		top: var(--s1);
		cursor: pointer;
	}

	.share-buttons {
		display: flex;
		flex-direction: row;
		gap: var(--s3);
	}

	.share-buttons p,
	.copy-link-cont p {
		font-size: var(--s1);
	}

	.share-button {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
		align-items: center;
		cursor: pointer;
	}

	.share-button img {
		width: 3.625rem;
	}

	.copy-link-cont {
		background-color: var(--color-gray-ultralight);
		border-radius: var(--s8);
		padding: var(--s0);
		border-radius: var(--s0);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.copy-link-cont p {
		display: inline-block;
		max-width: 17rem;
		overflow: hidden;
		--line-clamp: 1;
	}

	.copy-link-btn {
		background-color: var(--color-primary-25);
		padding: 0.375rem var(--s0);
		border-radius: var(--s0);
		min-width: 6rem;
	}

</style>