<template>
	<div v-if="show" :class="'generic-tooltip '+showTooltipClass">
		<div class="text--body2 tooltip-text capitalize-first">{{text}}</div>
		<!-- <div class="generic-tooltip-arrow"></div> -->
	</div>
</template>
<script type="text/javascript">
	export default {
		name: 'InterfaceTooltip',
		props: {
			show: {
				type: Boolean,
				required: false,
				default: false
			},
			positionEl: {
				type: Object,
				required: false
			},
			text: {
				type: String,
				required: false,
				default: ''
			}
		},
		computed: {
			showTooltipClass() {
				return this.show ? 'show' : ''
			}
		}
	}
</script>
<style type="text/css">
	.generic-tooltip {
		position: absolute;
    bottom: -3.5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-primary-25-opacity);
    padding: 0.5rem;
    color: var(--color-black);
    border-radius: calc(var(--radius) / 2);
    white-space: nowrap;
    transition: filter .2s;
    filter: opacity(0);
    z-index: unset;
	}
	.generic-tooltip.show {
		filter: opacity(1)
	}

	.generic-tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-top: 1rem solid var(--color-black);
		left: 50%;
		transform: translateX(-50%);
	}
	.tooltip-text {
		color: var(--color-black-text);
	}
</style>