<template>
	<div class="stack-horizontal user-and-tags" data-justify="spaced">
		<div v-if="tags" class="stack-horizontal horizontal-scrollable">
			<base-tag v-for="tag in tags" :key="tag" :text="tag"/>
		</div>
		<div class="stack-horizontal" data-align="center">
			<div v-for="(ua, z) in userActions" :key="z" class="user-action-icon">
				<interface-tooltip
					:show="showIcon(ua.icon)"
					:text="textTooltipFromIcon(ua.icon)"
				/>

				<base-icon 
				v-if="loadAction !== ua.icon"
				:data-icon="ua.icon"
				:text="ua.text" 
				:name="ua.icon" 
				hover-cirle 
				@icon-clicked="clickEvent('click-user-action', ua.icon)"
				/>
				<div v-else class="loader-user-and-tags"></div>
			</div>
		</div>
	</div>	
</template>

<script>
import _ from 'lodash';
import InterfaceTooltip from '@components/InterfaceTooltip.vue';
export default {
	name: 'InterfaceUserAndTags',
	components: { InterfaceTooltip },
	props: {
		tags: {
			type: Array,
			required: false
		},
		userActions: {
			type: Array
		},
		loadAction: {
			type: String,
			default: ''
		},
		showTooltip: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hovered: [],
			clicked: null
		}
	},
	methods: {
		clickEvent(event, type) {
			this.$emit('click-user-action', type)
		},
		c() {
			console.log('showTooltip', this.showTooltip)
			return this.showTooltip ? 'show' : ''
		},
		addIconHovered(icon) {
			this.hovered.push(icon)
		},
		removeIconHovered(icon) {
			this.hovered.splice(this.hovered.indexOf(icon), 1)
		},
		textTooltipFromIcon(icon) {
			let userAction = this.userActions.find(el => el.icon == icon)
			let text = userAction[userAction.clicked ? 'tooltipClicked' : 'tooltipHover']
			return text
		},
		showIcon(icon) {
			return this.hovered.indexOf(icon) > -1;
		}
	},
	watch: {
		userActions(n,o) {
			console.log('new user actions', n)
		}
	},
	mounted() {
		let interval = setInterval(() => {
			if(document.querySelector('.user-action-icon .icon')) {
				clearInterval(interval)
				Array.from(document.querySelectorAll('.user-action-icon .icon')).forEach(el => {
					el.onmouseenter = e => {
						let icon = e.target.dataset.icon;
						this.addIconHovered(icon)
					}
					el.onmouseleave = e => {
						let icon = e.target.dataset.icon;
						this.removeIconHovered(icon)
					}
				})
			}
		},1)
	}
}
</script>

<style lang="scss">
	.user-and-tags {
		--flow-space-horizontal: var(--s3);
		flex-wrap: wrap;
		z-index: 1;
		> * {
			--flow-space-horizontal: var(--s1);
		}
		.icon {
			cursor: pointer;
		}
	}

	.user-action-icon {
		position: relative;
		z-index: 2;
	}

	.user-action-tooltip {
		position: absolute;
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    padding: 0.5rem;
    color: var(--color-white);
    border-radius: calc(var(--radius) / 2);
    white-space: nowrap;
    transition: filter .2s;
    filter: opacity(0);
    z-index: 2;
	}
	.user-action-tooltip.show {
		filter: opacity(1)
	}

	.user-action-tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-top: 1rem solid var(--color-black);
		left: 50%;
		transform: translateX(-50%);
	}

	.loader-user-and-tags {
		border: 0.2rem solid var(--color-light);
		border-radius: 50%;
		border-top: 0.2rem solid var(--color-primary);
		width: auto;
		height: 24px;
		aspect-ratio: 1/1;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
</style>