<template>
  <section class="carousel-container flow" :class="{'with-shadow': withShadow}">

    <div class="dgrid-body carousel-title-cont">
      <div class="stack-horizontal title-cont" data-justify="spaced" data-align="center">
        <h3 class="auto-left capitalize-first">{{title}}</h3>
        <a :href="seeAllHref" @click.prevent>
          <base-button-ghost v-if="seeAllLink" class="see-all-button" :text="$t('see_all')" icon="arrow-right" @clicked="$emit('see-all-clicked')"/>
        </a>
      </div>
    </div>

    <ul class="dgrid-body stack-horizontal horizontal-scrollable">
      <li class="carousel-filter pointer text--body1 text-no-wrap" v-for="(filter, i) in filters" :key="i" @click="$emit('filter-clicked', filter.type)" :class="{selected: filter.selected}">{{filter.label}}</li>
    </ul>

    <template v-if="numColumns > 2">
      <div class="dgrid-wrapper-scrollx">
        <div :class="'dgrid-body dgrid-container ' + `dgrid-fix-${numColumns}-scroll-2-3`">
          <slot></slot>
        </div>         
      </div>
    </template>

    <template v-else>
      <div :class="'dgrid-body dgrid-container dgrid-minw-wrap'">
        <slot></slot>
      </div>      
    </template>

  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'InterfaceCardsCarousel',
  props: {
  	elements: Array,
    title: String,
    scrollable: {
      type: Boolean,
      required: false,
      default: false
    },
    columns: {
      type: Number,
      required: false,
      default: 4
    },
    type: {
      type: String,
      default: 'horizontal'
    },
    seeAllLink: Boolean,
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    wrapContents: {
      type: Boolean,
      default: false
    },
    withShadow: {
      type: Boolean,
      default: false,
      required: false
    },
    seeAllHref: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      gap: 0
    }
  },
  computed: {
    ...mapState({
      mobile: state => state.interface.mobile
    }),
    numColumns() {
      return this.columns ? this.columns : 4;
    },
  },
}
</script>

<style lang="scss">

  @import '@css/variables.scss';

  .carousel-container {
    gap: var(--s3);
    --flow-space: var(--s3);
  }
  .carousel-container .dgrid-wrapper-scrollx {
    --dgrid-scrollx-padding: 0;
  }
  .carousel-container.with-shadow .dgrid-wrapper-scrollx {
    --dgrid-scrollx-padding-top: var(--s0);
    --dgrid-scrollx-padding: var(--s4);
  }

  .carousel-container > ul {
    --flow-space-horizontal: var(--s1);
  }

  .carousel-title-cont {
    --flow-space-horizontal: var(--s3);
    .title-cont {
      flex-wrap: wrap;
    }
  }

  .carousel-filter.selected {
    text-decoration: underline;
  }

  .see-all-link {
    gap: var(--s0);
  }

  .see-all-button {
    --flow-space-horizontal: var(--s0);
    span {
      font-size: calc(1.125 * var(--base-font-size));
    }
  }

  [class*='.dgrid-fix'] .card{
    max-width: 23rem;
  }

  @media (max-width: $breakpoint-m) {

  }

  @media (max-width: $breakpoint-sm) {
    .carousel-container {
      // flex-wrap: wrap;
      // flex-direction: column;
      gap: var(--s1);
      --flow-space: var(--s1);
    }

    .carousel-container .dgrid-wrapper-scrollx {
      --dgrid-scrollx-padding: 0;
    }
    .carousel-container.with-shadow .dgrid-wrapper-scrollx {
      --dgrid-scrollx-padding-top: var(--s0);
      --dgrid-scrollx-padding: var(--s1);
    }

    .carousel-title-cont {
      --flow-space-horizontal: var(--s1);
    }
  }
</style>
