/* mixin to get an item from the CMS on page load by id;
  define a data property "itemType" (e.g. "events", "institutions", "stories");
  it will be available in the property "item";
*/
import { mapState, mapGetters, mapActions } from 'vuex';
import langMixin from '@mixins/lang.js';

export default {
  // this route prop MUST be defined in the client compontent (don't know why) 
  // props: { 
  //   id: {
  //     type: String
  //   }
  // },
  mixins: [ langMixin ],
  computed: {
    ...mapState('cms', ['loading']),
    ...mapGetters('cms', ['listItemById', 'listItemBySlug']),
    item() {
      // return this.listItemById(this.itemType, this.id); 
      return this.listItemBySlug(this.itemType, this.slug); 
    }
  },
  methods: {
    ...mapActions('cms', ['getListItem']),
    langCb() {
      this.getListItem({listName: this.itemType, slug: this.slug});
    }
  },
  created() {
    this.getListItem({listName: this.itemType, slug: this.slug});
  }
}